import React from "react"

import SpatiulVerdelMobile from "../images/spatiul-verde-m.jpg"
import SpatiulVerdelDesktop from "../images/spatiul-verde-d.jpg"
import ViitorulMobile from "../images/viitorul-m.jpg"
import ViitorulDesktop from "../images/viitorul.jpg"
import CapaceMobile from "../images/slider/419058397-capace_800x780.jpg"
import CapaceDesktop from "../images/slider/419058413-capace_1920x800.jpg"
import ReturoMobile from "../images/Banner_reciclare_1200x900.jpg"
import ReturoDesktop from "../images/Banner_reciclare_1920x800.jpg"

import ArrowLeft from "../images/arrow-left-green.svg"
import ArrowRight from "../images/arrow-right-green.svg"

class Slider extends React.Component {
  componentDidMount() {
    $("#slider").slick({
      slidesToShow: 1,
      prevArrow: ".slider-arrow-left",
      nextArrow: ".slider-arrow-right",
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000
    })
  }

  render() {
    return (
      <div className="slider-container">
        <div className="position-relative">
          <img
            className="numbers-arrow slider-arrow-left"
            src={ArrowLeft}
            alt=""
          />
          <div id="slider">
            <div>
              <a href="https://www.penny.ro/returo" _target="_blank" className="d-block">
                  <img
                      className="d-md-none"
                      src={ReturoMobile}
                      alt=""
                    />
                  <img
                    className="d-none d-md-block w-100"
                    src={ReturoDesktop}
                    alt=""
                  />
              </a>
            </div>

            { this.props.home == 1 ? (

               <div>
                <a href="/campanii" className="d-block">
                    <img
                        className="d-md-none"
                        src={ViitorulMobile}
                        alt=""
                      />
                    <img
                      className="d-none d-md-block w-100"
                      src={ViitorulDesktop}
                      alt=""
                    />
                </a>
              </div>
              
            ) : null }

            <div>
                <a href="/campanii" className="d-block">
                    <img
                        className="d-md-none"
                        src={CapaceMobile}
                        alt=""
                    />
                    <img
                        className="d-none d-md-block"
                        src={CapaceDesktop}
                        alt=""
                    />
                </a>
            </div>

          </div>
          <img
            className="numbers-arrow slider-arrow-right"
            src={ArrowRight}
            alt=""
          />
        </div>
      </div>
    )
  }
}

export default Slider
